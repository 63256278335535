import React from "react";
import { Divider } from "antd";
export default function index(props) {
  return (
    <Divider
      variant={props.variant ? props.variant : "solid"}
      orientation={props.orientation ? props.orientation : "center"}
      orientationMargin={
        props.orientationMargin ? props.orientationMargin : null
      }
      style={{
        borderColor: props.borderColor,
        marginTop: props.marginTop ? props.marginTop : null,
      }}
    >
      {props.text ? props.text : null}
    </Divider>
  );
}
