import React from "react";
import { Tag } from "antd";
export default function index(props) {
  return (
    <Tag
      color={props.background}
      style={{
        fontFamily: props.fontFamily,
        fontSize: props.fontSize,
        color: props.color,
        fontWeight: props.fontWeight,
        borderRadius: props.borderRadius,
      }}
    >
      {props.text}
    </Tag>
  );
}
