import React, { useState } from "react";
import "./home.css";
import { Col, Container, Row } from "react-bootstrap";
import Text from "../../components/text";
import { fonts } from "../../theme";
import Divider from "../../components/divider";
import { IoTicketOutline } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineGroups } from "react-icons/md";
import { LuUser2 } from "react-icons/lu";
import { MdPostAdd } from "react-icons/md";
import { FaUniversity } from "react-icons/fa";
import { LiaIndustrySolid } from "react-icons/lia";
import { PiCityLight } from "react-icons/pi";
import { IoMdTime } from "react-icons/io";
import { BsPeople } from "react-icons/bs";
import { FaCreativeCommonsNd } from "react-icons/fa";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export default function EventDetail() {
  const [aboutText, setAboutText] = useState(
    `This event is perfect for 49ers fans to experience an unforgettable game day full of excitement and team pride! This update extends the celebration into the night with a fan afterparty from 7:00 PM to 10:00 PM!`
  );
  return (
    <Container
      className={"event-detail-container"}
      style={{
        width: isMobile ? "100% !important" : "80%",
      }}
      fluid={isMobile ? true : false}
    >
      <Container
        className={"event-detail"}
        style={{
          width: isMobile ? "100% !important" : "79%",
        }}
        fluid={isMobile ? true : false}
      >
        <Row>
          <Col className={"event-detail-box"}>
            <div className={"event-detail-box-inner"}>
              <div className={"event-detail-box-item"}>
                <IoTicketOutline color={"#424530"} size={25} />
                <span className={"event-detail-my-status"}>Going</span>
              </div>
              <div className={"event-detail-box-item"}>
                <FaRegCalendarAlt color={"#424530"} size={22} />
                <span
                  className={"event-detail-my-status"}
                  style={{ color: "rgba(107,114,128)" }}
                >
                  Sun Oct 8th | 10:00 AM - 10:00 PM{" "}
                </span>
              </div>
              <div className={"event-detail-box-item"}>
                <IoLocationOutline color={"#424530"} size={22} />
                <span
                  className={"event-detail-my-status"}
                  style={{ color: "rgba(107,114,128)" }}
                >
                  Levi’s Stadium, Santa Clara, CA
                </span>
              </div>
              <div className={"event-detail-box-item"}>
                <MdOutlineGroups color={"#424530"} size={22} />
                <span
                  className={"event-detail-my-status"}
                  style={{ color: "rgba(107,114,128)" }}
                >
                  844 people are going
                </span>
              </div>
              <div className={"event-detail-box-item"}>
                <LuUser2 color={"#424530"} size={22} />

                <span
                  className={"event-detail-my-status"}
                  style={{ color: "rgba(107,114,128)" }}
                >
                  Created by Johnny
                </span>
              </div>
              <div className={"event-detail-box-item"}>
                <MdPostAdd color={"#424530"} size={22} />

                <span
                  className={"event-detail-my-status"}
                  style={{ color: "rgba(107,114,128)" }}
                >
                  Post
                </span>
              </div>
            </div>
          </Col>
          <Col className={"event-similarity"}>
            <div className={"event-similarity-inner"}>
              <div className={"event-similarity-inner-header"}>
                <Text
                  text={"Attendee Similarities "}
                  fontFamily={fonts.ROBOTO.REGULAR}
                  fontSize={"0.875rem"}
                  fontWeight={500}
                  color={"rgba(107, 114, 128)"}
                />
              </div>
              <Divider marginTop={"-5px"} />
              <div
                className={"event-detail-box-item"}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <FaUniversity color={"#424530"} size={25} />
                  <span
                    className={"event-detail-my-status"}
                    style={{ color: "rgba(107,114,128)" }}
                  >
                    Same University
                  </span>
                </div>
                <span
                  className={"event-detail-my-status"}
                  style={{ color: "#000000" }}
                >
                  27 people
                </span>
              </div>
              <div
                className={"event-detail-box-item"}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <LiaIndustrySolid color={"#424530"} size={25} />
                  <span
                    className={"event-detail-my-status"}
                    style={{ color: "rgba(107,114,128)" }}
                  >
                    Same Industry
                  </span>
                </div>
                <span
                  className={"event-detail-my-status"}
                  style={{ color: "#000000" }}
                >
                  18 people
                </span>
              </div>
              <div
                className={"event-detail-box-item"}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <IoMdTime color={"#424530"} size={25} />
                  <span
                    className={"event-detail-my-status"}
                    style={{ color: "rgba(107,114,128)" }}
                  >
                    Similar Time in City
                  </span>
                </div>
                <span
                  className={"event-detail-my-status"}
                  style={{ color: "#000000" }}
                >
                  15 people
                </span>
              </div>
              <div
                className={"event-detail-box-item"}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <BsPeople color={"#424530"} size={25} />
                  <span
                    className={"event-detail-my-status"}
                    style={{ color: "rgba(107,114,128)" }}
                  >
                    Age ( +/- 5 years )
                  </span>
                </div>
                <span
                  className={"event-detail-my-status"}
                  style={{ color: "#000000" }}
                >
                  34 people
                </span>
              </div>
              <div
                className={"event-detail-box-item"}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <FaCreativeCommonsNd color={"#424530"} size={25} />
                  <span
                    className={"event-detail-my-status"}
                    style={{ color: "rgba(107,114,128)" }}
                  >
                    Common Interests
                  </span>
                </div>
                <span
                  className={"event-detail-my-status"}
                  style={{ color: "#000000" }}
                >
                  20 matches
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        className="event-about"
        style={{ width: isMobile ? "100%" : "79%" }}
        fluid
      >
        <div className={"event_about-header"}>
          <Text
            text={"Event Details:"}
            fontFamily={fonts.ROBOTO.REGULAR}
            fontSize={"0.875rem"}
            fontWeight={500}
            color={"rgba(107, 114, 128)"}
          />
        </div>
        <Divider marginTop={"-5px"} />
        <div className={"event-about-box"}>
          <Text
            text={aboutText}
            fontFamily={fonts.ROBOTO.REGULAR}
            fontSize={"0.875rem"}
            fontWeight={500}
            color={"rgba(107, 114, 128)"}
          />
        </div>
      </Container>
    </Container>
  );
}
