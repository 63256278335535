import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "../../components/image";
import "./register.css";
import { fonts, images } from "../../theme";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Title from "../../components/title";
import Input from "../../components/input";
import Button from "../../components/button";
export default function index() {
  return (
    <Container className={"register-container"} fluid>
      <Row>
        <Col md={4}></Col>
        <Col md={4} className={"register-box"}>
          <div className={"register-box-header"}>
            <Image src={images.logo} className={"footer-logo"} width={"20%"} />
            <div
              className={"close-btn"}
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <IoIosCloseCircleOutline color={"#FFFFFF"} size={30} />
            </div>
          </div>
          <div className={"register-box-body"}>
            <Title
              text={`What's your number?`}
              color={"#FFFFFF"}
              fontFamily={fonts.ROBOTO.BOLD}
              fontSize={"34px"}
              marginLeft={"12px"}
              fontWeight={"bold"}
            />
            <div className={"footer-input-form"}>
              <Input
                type={"number"}
                width={"100%"}
                height={"50px"}
                placeholder={"Please provide your phone number"}
                marginTop={"10px"}
              />
              <span className={"input-message"}>
                We'll use this to send you updates about the event.
              </span>
              <Button
                text={"Send Code"}
                width={"100%"}
                height={"50px"}
                marginTop={"20px"}
                background={"#fd7e1496"}
                color={"#FFFFFF"}
                fontSize={"16px"}
              />
            </div>
          </div>
        </Col>
        <Col md={4}></Col>
      </Row>
    </Container>
  );
}
