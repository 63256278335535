import React from "react";

export default function index(props) {
  return (
    <p
      style={{
        fontFamily: props.fontFamily,
        lineHeight: props.lineHeight ? props.lineHeight : null,
        fontWeight: props.fontWeight ? props.fontWeight : null,
        color: props.color ? props.color : null,
        marginLeft: props.marginLeft ? props.marginLeft : null,
        marginRight: props.marginRight ? props.marginRight : null,
        marginTop: props.marginTop ? props.marginTop : null,
        fontSize: props.fontSize,
      }}
    >
      {props.text}
    </p>
  );
}
