import React from "react";
import { Container } from "react-bootstrap";
import Image from "../../components/image/index";
import "./home.css";
import { colors, fonts, images } from "../../theme";
import Title from "../../components/title";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { GrLocation } from "react-icons/gr";

export default function HomeBanner() {
  return (
    <Container className={"banner-container"} fluid>
      <BrowserView>
        <Image
          src={images.dummy}
          width={"100%"}
          height={"350px"}
          filter={"blur(2px)"}
          position={"relative"}
        />
      </BrowserView>

      <Container
        className={"banner-inner"}
        fluid
        style={{
          padding: isMobile ? 0 : null,
        }}
      >
        <Image
          src={images.dummy}
          width={isMobile ? "100%" : "60%"}
          height={"350px"}
          fluid={true}
        />
        <Container
          className={"detail-container"}
          style={{
            width: isMobile ? "100%" : "59.3%",
          }}
        >
          <Container className={"detail-container-body"}>
            <Image src={images.logo} fluid width={"99px"} height={"90px"} />
          </Container>
          <Container className={"detail-container-footer"}>
            <div className={"event-tag"}>
              <span className={"event-tag-text"}>#sports</span>
            </div>
            <Title
              text={"Snowboarding Trip - Utah"}
              fontFamily={fonts.POPPINS.LIGHT}
              color={colors.white}
              fontWeight={"bold"}
              fontSize={"25px"}
              marginTop={"10px"}
            />
            <div className={"event-time-box"}>
              <span className={"event-time-text"}>
                Fri, Mar 7th, 2025 at 12:00 AM MT
              </span>
            </div>
            <div className={"location-box"}>
              <GrLocation color={"#FFFFFF"} size={30} />
              <a href={"#"} className={"location-text"}>
                Levi’s Stadium, Santa Clara, CA
              </a>
            </div>
          </Container>
        </Container>
      </Container>
    </Container>
  );
}
